















import { defineComponent } from '@vue/composition-api';
import CustomHeading from './CDashedTitle.vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    bannerImg: {
      type: String,
      default: '',
    },
  },
  components: {
    CustomHeading,
  },

  setup() {},
});
