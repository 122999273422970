
















import { defineComponent } from '@vue/composition-api'
import CustomHeading from './CDashedTitle.vue'

export default defineComponent({
  components: {
    CustomHeading
  },
  props: {
    headText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    btnUrl: {
      type: String,
      default: ''
    },
    reverseCol: {
      type: Boolean,
      default: false
    },
    setBgColor: {
      type: Boolean,
      default: false
    },
    imgUrl: {
      type: String,
      default: ''
    },
    mobReverseCol: {
      type: Boolean,
      default: false
    }

  },
  methods: {
    buttonClickAction() {
      this.$router.push({path: this.localePath(this.btnUrl)})
    }
  },
  setup() {
    
  },
})
