










































import ref from 'vue';
import CustomAccordionItem from './CAccordionItem.vue';
import Icon from '../../almarwan/components/Icon.vue';

export default {
  name: 'CustomAccordion',
  components: {
    CustomAccordionItem,
    Icon,
  },
  props: {
    /**
     * The values of the accordion items
     * {
     *   id: String, // id of the item
     *   summary: String, // summary (title) of the item
     *   details: String, // the details (content) of the item
     *   icon?: String, // the prefix icon of the item only pass the name if needed
     *   disabled?: Boolean, // only pass if the item is disabled
     * }
     */
    accordionItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    // eslint-disable-next-line new-cap
    const opened = new ref<boolean[]>([]);
    return {
      opened,
    };
  },
};
