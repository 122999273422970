var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"w-full mx-auto px-4.5 lg:px-0 max-w-full sm:hidden md:flex"},[_c('custom-breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}})],1),_vm._v(" "),_c('NavBanner',{attrs:{"title":_vm.$i18n.t('Our services'),"subTitle":_vm.$i18n.t('WHY CHOOSE AL MARWAN'),"bannerImg":_vm.$device.isMobile
        ? '/ourServices/mob-banner-1.jpg'
        : '/ourServices/banner-1.jpg'}}),_vm._v(" "),_c('NavSection',{attrs:{"headText":_vm.$i18n.t('Holistic machinery solutions, for all your projects'),"title":_vm.$i18n.t('GCC award-winning heavy equipment rental company'),"subTitle":_vm.$i18n.t('machinery rentals'),"buttonText":_vm.$i18n.t('Learn more'),"btnUrl":"/services/machinery-rentals","description":_vm.machineryDesc,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-1-mob.png'
        : '/ourServices/photo-1.png'}}),_vm._v(" "),_c('NavSection',{attrs:{"title":_vm.$i18n.t('Authorized distributor of new equipment in the Middle East'),"subTitle":_vm.$i18n.t('new machinery for sale'),"buttonText":_vm.$i18n.t('Learn more'),"btnUrl":"/services/new-machinery-sales","reverseCol":true,"description":_vm.desc2,"setBgColor":true,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-2-mob.jpg'
        : '/ourServices/photo-2.jpg'}}),_vm._v(" "),_c('NavSection',{attrs:{"title":_vm.$i18n.t('Trusted supplier of ready to work used heavy equipment'),"subTitle":_vm.$i18n.t('used machinery for sale'),"buttonText":_vm.$i18n.t('Learn more'),"btnUrl":"/services/used-machinery-sales","description":_vm.desc3,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-3-mob.png'
        : '/ourServices/photo-3.png'}}),_vm._v(" "),_c('NavSection',{attrs:{"title":_vm.$i18n.t(
        'Get genuine heavy equipment spare parts and attachments quickly'
      ),"subTitle":_vm.$i18n.t('Machinery parts & attachments'),"buttonText":_vm.$i18n.t('Learn more'),"btnUrl":"/services/parts-attachments-sales","reverseCol":true,"description":_vm.desc4,"setBgColor":true,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-4-mob.jpg'
        : '/ourServices/photo-4.png'}}),_vm._v(" "),_c('NavSection',{attrs:{"title":_vm.$i18n.t(
        'Get quick approvals with low monthly payments and flexible plans'
      ),"subTitle":_vm.$i18n.t('Machinery FINANCING'),"buttonText":_vm.$i18n.t('Find out more'),"btnUrl":"/services/machinery-financing","description":_vm.desc3,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-5-mob.jpg'
        : '/ourServices/photo-5.png'}}),_vm._v(" "),_c('div',{staticClass:"flex gap-5 lg:gap-8 justify-center w-full mx-auto sm:pb-12 lg:pb-24 sm:flex-col md:flex-row lg:flex-row sm:px-4 md:px-8"},[_c('NavBlackTile',{attrs:{"title":_vm.$i18n.t('Financial institutions'),"desc":_vm.$i18n.t(
          'We work with banks and handle the process for you to get the equipment you need.'
        ),"iconUrl":"/ourServices/bank.svg"}}),_vm._v(" "),_c('NavBlackTile',{attrs:{"title":_vm.$i18n.t('Al Marwan financial'),"desc":_vm.$i18n
          .t(
            'Get the know-how of both financial & equipment expertise from our financing professionals.'
          )
          .toString(),"iconUrl":"/ourServices/dollar.svg"}}),_vm._v(" "),_c('NavBlackTile',{attrs:{"title":_vm.$i18n.t('Bulk purchase offers'),"desc":_vm.$i18n.t(
          'Get the most for less. Bulk-purchase offers valid for select products.'
        ),"iconUrl":"/ourServices/cash.svg"}})],1),_vm._v(" "),_c('NavSection',{attrs:{"title":_vm.$i18n.t(
        '285,000+ sq. ft. specialized workshop facilities & 35+ mobilized service trucks'
      ),"subTitle":_vm.$i18n.t('Workshop & MAINTENANCE'),"buttonText":_vm.$i18n.t('Request service'),"btnUrl":"/services/machinery-maintenance","reverseCol":true,"description":_vm.desc6,"setBgColor":true,"imgUrl":_vm.$device.isMobileOrTablet
        ? '/ourServices/photo-6-mob.jpg'
        : '/ourServices/photo-6.png'}}),_vm._v(" "),_c('div',{staticClass:"w-full lg:py-24 sm:py-12"},[_c('OurLocation',{staticClass:"p-0"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }