






















































































































































import { defineComponent } from '@vue/composition-api';
import LatestNews from '../../../almarwan/views/blog/components/LatestNews.vue';
import NavBanner from '../../../components/custom/CNavBanner.vue';
import DealersLogos from '../../../pages/Dealers/components/DealersLogos.vue';
import CustomBreadcrumbs from '../../../components/custom/CBreadcrumbs.vue';
import OurLocation from '../../../almarwan/components/OurLocation.vue';
import NavSection from '../../../components/custom/CNavSection.vue';
import NavBlackTile from '../../../components/custom/CNavBlackTile.vue';
import CustomAccordion from '../../../components/custom/CAccordion.vue';
import CustomHeading from '../../../components/custom/CDashedTitle.vue';
import type { MetaInfo } from 'vue-meta';
import { useUiHelpers } from "~/composables";

export default defineComponent({
  components: {
    LatestNews,
    NavBanner,
    OurLocation,
    NavSection,
    NavBlackTile,
    CustomAccordion,
    CustomHeading,
    DealersLogos,
    CustomBreadcrumbs,
  },
  data() {
    return {
      breadcrumbs: [{ text: this.$i18n.t('Services'), link: this.localePath('/services') }],
      machineryDesc:
      this.$i18n.t('Renting heavy equipment from Al Marwan ensures our clients have access to over 3,000 units of specialized heavy machinery capable of smoothly completing projects in UAE, Oman, and Saudi Arabia, supported by a team of expert operators and multiple mobile workshops. With each rented machine, we provide well-trained operators with HSE Safety and OSHAD certification.'),
      desc2:
      this.$i18n.t("As authorized distributors of new heavy equipment, including genuine Kobelco, Powerscreen, Dynapac, ABI, and other global brands, we serve the growing demand for quality machinery in the Middle East. We offer comprehensive warranties, OEM parts, service contracts, and operator training to ensure your project's success.").toString(),
      desc3:
      this.$i18n.t('Get your project running by choosing one of our multiple financing options. We offer flexible financing solutions that are tailored to meet your heavy machinery needs. From financial institutions, credit facilities, buy now, pay later schemes, Installments, and other flexible payment plans, our clients can find what they need to get their projects back on track.'),
      desc4:
      this.$i18n.t('We supply OEM Toku, Jisung, and Simex machinery attachments as authorized distributors for the UAE market. We also carry regularly-in-stock heavy equipment spare parts including mechanical, electric, and hydraulic machinery components.'),
      desc5:
      this.$i18n.t('Get your project running by choosing one of our multiple financing options. We offer flexible financing solutions that are tailored to meet the needs of any business.'),
      desc6:
      this.$i18n.t('Al Marwan has one of the largest and oldest heavy machinery workshops in the UAE, with several fully-equipped workshops around three main locations in the GCC including UAE, Saudi Arabia, and Oman. We also have 35+ mobile workshops for quick and convenient breakdown/emergency response on project sites.'),

      pageTitle: this.$i18n.t('Services | Al Marwan Heavy Machinery'),
      description: this.$i18n.t(
        'New & used heavy road works, mining, quarry & aggregate, oil & gas, and construction equipment on rent & sale'
      ),
    };
  },
  methods: {
    toggleAccordion(element) {
      const content = element.nextElementSibling;
      content.classList.toggle('show');
    },
  },
  head(): MetaInfo {
    const title = this.$i18n.t('serviceTitle').toString()
    const description = this.$i18n.t('serviceDesc').toString()
    const url = this.getFullUrl(this.localePath('/services'))
    const meta = []
    const script = []
    const link = []
    meta.push({ 
      property: 'title', 
      name: 'title', 
      content: title 
    })
    meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: title
    });
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title
    });
    meta.push({
      hid: 'twitter:text:title',
      name: 'twitter:text:title',
      content: title
    });

    meta.push({
      hid: 'url',
      name: 'url',
      content: url,
    });
    meta.push({
      hid: 'og:url',
      name: 'og:url',
      content: url,
    });

    meta.push({
      hid: 'description',
      name: 'description',
      content: description
    });
    meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: description
    });
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: description
    });
    meta.push({ 
      hid: 'apple-mobile-web-app-title', 
      name: 'apple-mobile-web-app-title', 
      content: title 
    });
    meta.push({ 
      hid: 'og:apple-mobile-web-app-title', 
      name: 'og:apple-mobile-web-app-title', 
      content: title 
    });
    meta.push({ 
      hid: 'og:site_name', 
      name: 'og:site_name', 
      content: title 
    });
    meta.push({ 
      hid: 'site_name', 
      name: 'site_name', 
      content: title 
    });
    meta.push({ 
      property: 'og:image',
      content: `${process.env.BASE_URL}favicon.ico`
    },
    { 
      property: 'twitter:image', 
      content: `${process.env.BASE_URL}favicon.ico`
    });
    script.push({
      type: 'application/ld+json',
      class: 'saswp-schema-markup-output',
      json: [
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": title,
        "url": url,
        "description": this.$i18n.t('serviceDesc').toString(),
        "publisher": {
          "@type": "Organization",
          "name": title,
          "logo": {
            "@type": "ImageObject",
            "url": `${process.env.BASE_URL}_nuxt/icons/icon_512x512.3b9037.png`,
            "width": 630,
            "height": 630
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": url
        }
      }]
    });
    link.push({
      rel: 'canonical',
      href: url,
    });
    return {
      title,
      meta,
      script,
      link
    };
  },
  setup() {
    const { getFullUrl } = useUiHelpers()
    return {
      getFullUrl
    }
  },
});
