























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomAccordionItem',
  props: {
    /**
     * Status of the accordion item
     * passing it will make the accordion item open
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * summary css classes
     */
    summaryClass: [Array, String, Object],
    /**
     * Status of the accordion item
     * passing it will disable the accordion item
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
