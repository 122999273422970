







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    iconUrl: {
      type: String,
      default: '/ourServices/cash.svg'
    }
  },
  setup() {
    
  },
})
